/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

const letterColor = '#000';
const logoColor = '#ad362b';

function LogoLight({}) {
  return (
    <svg width="170" height="55" viewBox="0 0 170 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1106 3L48.2214 15.4153V40.1636L27.1106 52.5789L6 40.1636V15.4153L27.1106 3ZM9.4834 38.1861V30.8266L25.3687 40.1642V47.5284L9.4834 38.1861ZM25.3687 36.1385L9.4834 26.8009V19.4412L25.3687 28.7789V36.1385ZM28.8521 47.5287V28.7796L35.0564 25.1326V43.8799L28.8521 47.5287ZM38.5398 41.8313L44.738 38.1861V19.4417L38.5398 23.085V41.8313ZM33.3755 10.7105L27.1106 7.02612L11.1648 16.404L17.4272 20.0851L33.3755 10.7105ZM36.7988 12.7238L20.8516 22.0979L27.1111 25.7773L43.0569 16.4042L36.7988 12.7238Z"
        fill={logoColor}
      />
      <path
        d="M70.0036 38.6829C68.3702 38.6829 66.7745 38.4615 65.2165 38.0187C63.6585 37.5759 62.4146 37.0159 61.4849 36.3387L63.2941 32.627C64.1988 33.2522 65.2793 33.7601 66.5358 34.1508C67.8174 34.5154 69.0738 34.6978 70.3051 34.6978C73.1196 34.6978 74.5268 33.9294 74.5268 32.3926C74.5268 31.6633 74.1624 31.1554 73.4337 30.8689C72.7301 30.5824 71.5867 30.3089 70.0036 30.0484C68.3451 29.7879 66.9881 29.4884 65.9327 29.1498C64.9024 28.8112 63.9978 28.2251 63.2188 27.3916C62.4649 26.5321 62.088 25.347 62.088 23.8362C62.088 21.8567 62.8795 20.2808 64.4626 19.1087C66.0709 17.9106 68.232 17.3115 70.9459 17.3115C72.328 17.3115 73.7101 17.4808 75.0922 17.8194C76.4743 18.132 77.6051 18.5618 78.4846 19.1087L76.6753 22.8204C74.9665 21.7785 73.0442 21.2576 70.9082 21.2576C69.5261 21.2576 68.4707 21.479 67.742 21.9218C67.0384 22.3385 66.6866 22.8986 66.6866 23.6018C66.6866 24.3832 67.0635 24.9432 67.8174 25.2818C68.5963 25.5944 69.79 25.8939 71.3982 26.1804C73.0065 26.4409 74.3257 26.7405 75.356 27.0791C76.3863 27.4177 77.2658 27.9907 77.9946 28.7982C78.7484 29.6056 79.1254 30.7517 79.1254 32.2363C79.1254 34.1898 78.3087 35.7526 76.6753 36.9248C75.0419 38.0969 72.818 38.6829 70.0036 38.6829Z"
        fill={letterColor}
      />
      <path
        d="M94.491 37.2764C93.9382 37.7452 93.2597 38.0969 92.4556 38.3313C91.6766 38.5657 90.8473 38.6829 89.9678 38.6829C87.7565 38.6829 86.0477 38.0838 84.8415 36.8857C83.6353 35.6875 83.0322 33.9424 83.0322 31.6503V12.9357H87.7439V17.7022H93.1341V21.6092H87.7439V31.5331C87.7439 32.5489 87.9826 33.3303 88.4601 33.8773C88.9627 34.3982 89.6537 34.6587 90.5332 34.6587C91.5886 34.6587 92.4681 34.3722 93.1717 33.7991L94.491 37.2764Z"
        fill={letterColor}
      />
      <path
        d="M117.399 17.5459V38.4094H112.913V35.7136C112.134 36.7034 111.167 37.4457 110.011 37.9406C108.88 38.4355 107.624 38.6829 106.242 38.6829C104.282 38.6829 102.523 38.2401 100.965 37.3545C99.4317 36.4689 98.2256 35.2187 97.3461 33.6038C96.4917 31.9889 96.0645 30.1135 96.0645 27.9777C96.0645 25.8418 96.4917 23.9795 97.3461 22.3906C98.2256 20.7757 99.4317 19.5255 100.965 18.6399C102.523 17.7543 104.282 17.3115 106.242 17.3115C107.548 17.3115 108.742 17.5459 109.823 18.0148C110.928 18.4836 111.883 19.1739 112.687 20.0855V17.5459H117.399ZM106.769 34.5024C108.503 34.5024 109.936 33.9033 111.066 32.7052C112.197 31.507 112.763 29.9312 112.763 27.9777C112.763 26.0242 112.197 24.4483 111.066 23.2502C109.936 22.052 108.503 21.453 106.769 21.453C105.035 21.453 103.603 22.052 102.472 23.2502C101.367 24.4483 100.814 26.0242 100.814 27.9777C100.814 29.9312 101.367 31.507 102.472 32.7052C103.603 33.9033 105.035 34.5024 106.769 34.5024Z"
        fill={letterColor}
      />
      <path
        d="M132.798 38.6829C130.713 38.6829 128.841 38.2271 127.182 37.3155C125.524 36.4038 124.229 35.1405 123.3 33.5256C122.37 31.8847 121.905 30.0354 121.905 27.9777C121.905 25.92 122.37 24.0837 123.3 22.4688C124.229 20.8539 125.511 19.5906 127.144 18.679C128.803 17.7673 130.688 17.3115 132.798 17.3115C134.784 17.3115 136.517 17.7283 138 18.5618C139.508 19.3953 140.639 20.5934 141.392 22.1562L137.774 24.3441C137.196 23.3804 136.467 22.6641 135.588 22.1953C134.733 21.7004 133.791 21.453 132.761 21.453C131.002 21.453 129.544 22.052 128.388 23.2502C127.232 24.4223 126.654 25.9981 126.654 27.9777C126.654 29.9572 127.22 31.5461 128.351 32.7442C129.507 33.9163 130.977 34.5024 132.761 34.5024C133.791 34.5024 134.733 34.268 135.588 33.7991C136.467 33.3042 137.196 32.5749 137.774 31.6112L141.392 33.7991C140.613 35.3619 139.47 36.5731 137.962 37.4327C136.48 38.2662 134.758 38.6829 132.798 38.6829Z"
        fill={letterColor}
      />
      <path
        d="M154.406 38.6829C152.32 38.6829 150.448 38.2271 148.79 37.3155C147.131 36.4038 145.837 35.1405 144.907 33.5256C143.977 31.8847 143.512 30.0354 143.512 27.9777C143.512 25.92 143.977 24.0837 144.907 22.4688C145.837 20.8539 147.118 19.5906 148.752 18.679C150.41 17.7673 152.295 17.3115 154.406 17.3115C156.391 17.3115 158.125 17.7283 159.608 18.5618C161.115 19.3953 162.246 20.5934 163 22.1562L159.381 24.3441C158.803 23.3804 158.075 22.6641 157.195 22.1953C156.341 21.7004 155.398 21.453 154.368 21.453C152.609 21.453 151.152 22.052 149.996 23.2502C148.84 24.4223 148.262 25.9981 148.262 27.9777C148.262 29.9572 148.827 31.5461 149.958 32.7442C151.114 33.9163 152.584 34.5024 154.368 34.5024C155.398 34.5024 156.341 34.268 157.195 33.7991C158.075 33.3042 158.803 32.5749 159.381 31.6112L163 33.7991C162.221 35.3619 161.078 36.5731 159.57 37.4327C158.087 38.2662 156.366 38.6829 154.406 38.6829Z"
        fill={letterColor}
      />
    </svg>
  );
}

const LogoFull = () => {
  return <LogoLight />;
};

export default LogoFull;
