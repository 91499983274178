import * as React from 'react';
import { useMemo } from 'react';
import { discoveryApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DataLink } from './LinksItem';
import { Content, Header, Page } from '@backstage/core-components';
import Error from '@material-ui/icons/Error';
import { LinksList } from './LinksList';
import { LinksTags } from './LinksTags';

type LinksReduced = {
  tags: string[];
  items: DataLink[];
};

const useStyles = makeStyles(theme => ({
  tags: {
    order: 1,
    [theme.breakpoints.up(`md`)]: {
      order: 2,
    },
  },
  list: {
    order: 2,
    [theme.breakpoints.up(`md`)]: {
      order: 1,
    },
  },
}));

export const LinksPage = () => {
  const discoveryApi = useApi(discoveryApiRef);
  const { fetch } = useApi(fetchApiRef);
  const styles = useStyles();
  const { value, error } = useAsync(async (): Promise<DataLink[]> => {
    const response = await fetch(`${await discoveryApi.getBaseUrl('links')}/links`);

    if (!response.ok) {
      return Promise.reject(await response.json());
    }

    return response.json();
  }, []);

  const [selectedTags, selectedTagsSet] = React.useState<string[]>([]);
  const { tags, items } = useMemo<LinksReduced>(() => {
    if (!value) return { tags: [], items: [] };
    return {
      items: (value ?? []).filter(item => selectedTags.length === 0 || selectedTags.includes(item.category!)),
      tags: Array.from(new Set((value ?? []).filter(item => !!item.category).map(item => item.category!))),
    };
  }, [value, selectedTags]);

  function onTagClicked(tag: string) {
    if (selectedTags.includes(tag)) {
      selectedTagsSet(selectedTags.filter(t => t !== tag));
    } else {
      selectedTagsSet([...(selectedTags ?? []), tag]);
    }
  }

  if (error) {
    return <Error />;
  }
  return (
    <Page themeId="links">
      <Header title="Links" />
      <Content>
        <Grid container spacing={4} style={{ maxWidth: 1100, margin: '0 auto' }}>
          <Grid item xs={12} md={5} spacing={4} className={styles.tags}>
            <Typography variant="h3">Categories</Typography>
            <LinksTags onTagClick={onTagClicked} tags={tags} selectedTags={selectedTags} />
          </Grid>
          <Grid item xs={12} md={7} className={styles.list}>
            <LinksList items={items} headers={tags} />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
