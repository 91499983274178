import React from 'react';
import { Grid, makeStyles, Paper, Theme } from '@material-ui/core';

import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs';
import { StackOverflowIcon, StackOverflowSearchResultListItem } from '@backstage/plugin-stack-overflow';
import { SearchType } from '@backstage/plugin-search';
import { SearchBar, SearchPagination, SearchResult } from '@backstage/plugin-search-react';
import { CatalogIcon, Content, DocsIcon, Header, Page } from '@backstage/core-components';
import { ConfluenceResultListItem } from '@internal/plugin-confluence';

const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    padding: theme.spacing(1, 0),
  },
  filters: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
}));

const SearchPage = () => {
  const classes = useStyles();
  // const { types } = useSearch();
  // const catalogApi = useApi(catalogApiRef);

  return (
    <Page themeId="home">
      <Header title="Search" />
      <Content>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Paper className={classes.bar}>
              <SearchBar />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <SearchType.Tabs
              types={[
                {
                  value: 'read-write-docs',
                  name: 'Internal Docs',
                },
                {
                  value: 'confluence',
                  name: 'Confluence',
                  // icon: <DocsIcon />,
                },
                {
                  value: 'read-write-blog',
                  name: 'Blog',
                },
                {
                  value: 'software-catalog',
                  name: 'Software Catalog',
                },
                {
                  value: 'techdocs',
                  name: 'Documentation',
                },
              ]}
            />
          </Grid>
          <Grid item xs={9}>
            <SearchPagination />
            <SearchResult>
              <CatalogSearchResultListItem icon={<CatalogIcon />} />
              <TechDocsSearchResultListItem icon={<DocsIcon />} />
              <StackOverflowSearchResultListItem icon={<StackOverflowIcon />} />
              <ConfluenceResultListItem />
            </SearchResult>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

export const searchPage = <SearchPage />;
