/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 'auto',
  },
  path: {
    fill: '#ad362b',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className={classes.path}
        d="M27.1106 3L48.2214 15.4153V40.1636L27.1106 52.5789L6 40.1636V15.4153L27.1106 3ZM9.4834 38.1861V30.8266L25.3687 40.1642V47.5284L9.4834 38.1861ZM25.3687 36.1385L9.4834 26.8009V19.4412L25.3687 28.7789V36.1385ZM28.8521 47.5287V28.7796L35.0564 25.1326V43.8799L28.8521 47.5287ZM38.5398 41.8313L44.738 38.1861V19.4417L38.5398 23.085V41.8313ZM33.3755 10.7105L27.1106 7.02612L11.1648 16.404L17.4272 20.0851L33.3755 10.7105ZM36.7988 12.7238L20.8516 22.0979L27.1111 25.7773L43.0569 16.4042L36.7988 12.7238Z"
      />
    </svg>
  );
};

export default LogoIcon;
