/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
import { Divider, Link, makeStyles } from '@material-ui/core';
import GroupsIcon from '@material-ui/icons/Group';
import LinkIcon from '@material-ui/icons/Link';
import ExtensionIcon from '@material-ui/icons/Extension';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import BlogIcon from '@material-ui/icons/Edit';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import ComputerIcon from '@material-ui/icons/ComputerSharp';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { NavLink } from 'react-router-dom';
import { Settings as SidebarSettings, UserSettingsSignInAvatar } from '@backstage/plugin-user-settings';
import {
  Sidebar,
  sidebarConfig,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { Whatshot } from '@material-ui/icons';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link component={NavLink} to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarItem icon={CollectionsBookmarkIcon} to="documentation" text="Docs" />
        <SidebarItem icon={Whatshot} to="releases" text="Release notes" />
        <SidebarItem icon={BlogIcon} to="blog" text="Blog" />
        <SidebarItem icon={LinkIcon} to="links" text="Links" />
        <Divider style={{ width: '100%' }} />
        <SidebarItem icon={ComputerIcon} to="https://equipment.stacc.com" text="Equipment" target="_blank" />
        <Divider style={{ width: '100%' }} />
        {/*
          TODO: Add projects
          <SidebarItem icon={BusinessIcon} to="projects" text="Projects" />
        */}
        <SidebarItem icon={ExtensionIcon} to="services" text="Services" />
        <SidebarItem icon={GroupsIcon} to="teams" text="Teams" />
        <RequirePermission permission={catalogEntityCreatePermission}>
          <SidebarItem icon={CreateComponentIcon} to="create" text="Create" />
        </RequirePermission>
        {/* End global nav */}
      </SidebarGroup>
      <SidebarSpace />
      <SidebarGroup label="Settings" icon={<UserSettingsSignInAvatar />} to="/settings">
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
