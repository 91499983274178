import * as React from 'react';
import { List, Typography } from '@material-ui/core';
import { DataLink, LinksItem } from './LinksItem';

type Props = {
  items: DataLink[];
  headers: string[];
};
export const LinksList = ({ items, headers }: Props) => {
  const sorted = items.sort((a, b) => {
    if (!!a.category && !!b.category) {
      return a.category.localeCompare(b.category);
    }
    return 0;
  });
  const categoriesUsed = (headers ?? []).reduce<Record<string, boolean>>((acc, curr) => {
    if (!acc[curr]) {
      acc[curr] = false;
    }
    return acc;
  }, {});
  return (
    <List style={{ width: '100%' }}>
      {sorted.map(link => {
        if (link.category && !categoriesUsed[link.category]) {
          categoriesUsed[link.category] = true;
          return (
            <>
              <Typography color="primary" variant="h3" gutterBottom>
                {link.category}
              </Typography>
              <LinksItem link={link} />
            </>
          );
        }
        return <LinksItem link={link} />;
      })}
    </List>
  );
};
