import {
  EntityAboutCard,
  EntityLayout,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntityRelationWarning,
  EntitySwitch,
  hasCatalogProcessingErrors,
  hasRelationWarnings,
  isOrphan,
} from '@backstage/plugin-catalog';
import { EntityGithubActionsContent } from '@backstage/plugin-github-actions';
import { Grid } from '@material-ui/core';
import { EntityGithubPullRequestsContent } from '@roadiehq/backstage-plugin-github-pull-requests';
import { KosliCard, isKosliAvailable } from 'backstage-plugin-kosli';
import { EntitySnykContent, SnykOverview, isSnykAvailable } from 'backstage-plugin-snyk';
import React from 'react';

export const githubSharedContent = (
  <>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/github-actions" title="Github Actions">
      <EntityGithubActionsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/snyk" title="Security" if={isSnykAvailable}>
      <EntitySnykContent />
    </EntityLayout.Route>
  </>
);

export const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

export const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item md={12} lg={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isKosliAvailable}>
        <Grid item md={12} lg={6}>
          <KosliCard />
          {/* <EntityCatalogGraphCard variant="gridItem" height={400}/>*/}
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isSnykAvailable}>
        <Grid item md={2}>
          <SnykOverview />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
