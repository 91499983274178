import { createTheme, genPageTheme, lightTheme, shapes } from '@backstage/theme';

export const staccTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#AD362B',
    },
    secondary: {
      main: '#467eff',
    },
    error: {
      main: '#AD362B',
    },
    warning: {
      main: '#ffba30',
    },
    info: {
      main: '#467eff',
    },
    success: {
      main: '#66cd73',
    },
    background: {
      default: '#f9f9f9',
      paper: '#f3f1f0',
    },
    banner: {
      info: '#467eff',
      error: '#e63429',
      text: '#ad362b',
      link: '#565a6e',
    },
    errorBackground: '#f18a84',
    warningBackground: '#ffdb94',
    infoBackground: '#adc6ff',
    navigation: {
      background: '#f9f9f9',
      indicator: '#ad362b',
      color: '#1E1E1E',
      selectedColor: '#ad362b',
      navItem: {
        hoverBackground: '#f3f1f0',
      },
    },
  },
  defaultPageTheme: 'home',
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontFamily: 'Permanent Marker, cursive',
      fontWeight: 700,
      marginBottom: 2,
      fontSize: '2.5rem',
    },
    h2: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 700,
      marginBottom: 2,
      fontSize: '2rem',
    },
    h3: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 700,
      marginBottom: 2,
      fontSize: '1.5rem',
    },
    h4: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 700,
      marginBottom: 2,
      fontSize: '1.25rem',
    },
    h5: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 700,
      marginBottom: 2,
      fontSize: '1rem',
    },
    h6: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 700,
      marginBottom: 2,
      fontSize: '0.875rem',
    },
    htmlFontSize: 16,
  },
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({
      colors: ['#ad362b', '#ad362b'],
      shape: shapes.none,
      options: {
        fontColor: '#ad362b',
      },
    }),
    documentation: genPageTheme({
      colors: ['#ad362b', '#ad362b'],
      shape: shapes.none,
      options: {
        fontColor: '#ad362b',
      },
    }),
    tool: genPageTheme({
      colors: ['#ad362b', '#ad362b'],
      shape: shapes.none,
      options: {
        fontColor: '#ad362b',
      },
    }),
    service: genPageTheme({
      colors: ['#ad362b', '#ad362b'],
      shape: shapes.none,
      options: {
        fontColor: '#ad362b',
      },
    }),
    website: genPageTheme({
      colors: ['#ad362b', '#ad362b'],
      shape: shapes.none,
      options: {
        fontColor: '#ad362b',
      },
    }),
    library: genPageTheme({
      colors: ['#ad362b', '#ad362b'],
      shape: shapes.none,
      options: {
        fontColor: '#ad362b',
      },
    }),
    other: genPageTheme({
      colors: ['#ad362b', '#ad362b'],
      shape: shapes.none,
      options: {
        fontColor: '#ad362b',
      },
    }),
    app: genPageTheme({
      colors: ['#ad362b', '#ad362b'],
      shape: shapes.none,
      options: {
        fontColor: '#ad362b',
      },
    }),
    apis: genPageTheme({
      colors: ['#ad362b', '#ad362b'],
      shape: shapes.none,
      options: {
        fontColor: '#ad362b',
      },
    }),
  },
});
