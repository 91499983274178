import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const articclePlugin = createPlugin({
  id: 'articcle',
  routes: {
    root: rootRouteRef,
  },
});

export const WritePage = articclePlugin.provide(
  createRoutableExtension({
    name: 'Write',
    component: () => import('./components/Write').then(m => m.Editor),
    mountPoint: rootRouteRef,
  }),
);

export const ReadPage = articclePlugin.provide(
  createRoutableExtension({
    name: 'Read',
    component: () => import('./components/Read').then(m => m.Read),
    mountPoint: rootRouteRef,
  }),
);

export const ReadArticle = articclePlugin.provide(
  createRoutableExtension({
    name: 'Read',
    component: () => import('./components/Read').then(m => m.ReadArticle),
    mountPoint: rootRouteRef,
  }),
);
