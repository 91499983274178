import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { CatalogEntityPage, CatalogIndexPage, catalogPlugin } from '@backstage/plugin-catalog';
import { CatalogImportPage, catalogImportPlugin, DefaultImportPage } from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
//  import {TechRadarPage} from '@backstage/plugin-tech-radar';
import { TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage } from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
// import {HomePage} from './components/home/HomePage';
import { AlertDisplay, OAuthRequestDialog, SignInPage } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
// import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
// import {RequirePermission} from '@backstage/plugin-permission-react';
// import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
// import {TechRadarPage} from "@backstage/plugin-tech-radar";
import { CatalogGraphPage, catalogGraphPlugin } from '@backstage/plugin-catalog-graph';
import { HomePage } from './components/home/HomePage';
import { KosliPage } from 'backstage-plugin-kosli';
import { TeamsOverview } from './components/teams/TeamsOverview';
import { staccTheme } from './theme/stacc.theme';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { ServicesOverview } from './components/services/ServicesOverview';
import { LinksPage } from './components/links/LinksPage';
import { WritePage, ReadPage, ReadArticle } from '@internal/plugin-readwrite';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';

const app = createApp({
  apis,
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'microsoft-auth-provider',
          title: 'Stacc AD',
          message: 'Sign in using Stacc AD',
          apiRef: microsoftAuthApiRef,
        }}
      />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
  },
  themes: [
    {
      id: 'stacc-theme',
      title: 'Stacc Theme',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={staccTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage />}>
      {entityPage}
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route path="/teams" element={<TeamsOverview />} />
    <Route path="/services" element={<ServicesOverview />} />
    <Route path="/links" element={<LinksPage />} />
    <Route path="/import" element={<CatalogImportPage />}>
      <DefaultImportPage />
    </Route>
    {/* <RequirePermission permission={catalogEntityCreatePermission}>*/}
    <Route path="/create" element={<ScaffolderPage />} />
    {/* </RequirePermission>*/}
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    {/* <RequirePermission permission={catalogEntityCreatePermission}>*/}
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage />}>
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    {/* </RequirePermission>*/}
    <Route path="/write-docs" element={<WritePage key="writeDocs" docType="docs" />} />
    <Route path="/write-release-note" element={<WritePage key="writeReleaseNotes" docType="release-note" />} />
    <Route path="/write-blog" element={<WritePage key="writeBlog" docType="blog" />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    {/* <Route path="/tech-radar" element={<TechRadarPage height={800} width={500} />}/>*/}
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/kosli" element={<KosliPage />} />
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/blog" element={<ReadPage key="blog" docType="blog" headerTitle="Blog" />} />
    <Route
      path="/documentation"
      element={<ReadPage key="documentation" docType="docs" headerTitle="Documentation" />}
    />
    <Route path="/releases" element={<ReadPage key="releases" docType="release-note" headerTitle="Release Notes" />} />
    <Route path="/read/:id" loader={({ params }) => params.id!} element={<ReadArticle />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
