import * as React from 'react';
import { Avatar, Link, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';

export type DataLink = {
  title: string;
  description: string;
  href: string;
  icon?: string;
  category?: string;
};

type Props = {
  link: DataLink;
};

const useStyles = makeStyles(theme => ({
  large: {
    width: 171,
    height: 110,
    objectFit: 'cover',
  },
  text: {},
  root: {},
  listItemRoot: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    height: 100,
  },
  secondary: {
    maxHeight: 100,
    '&>span': {
      height: 50,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const getFallbackIcon = (title: string) => `https://placehold.it/100/100?text=${title.split(' ')[0]}`;
export const LinksItem = ({ link }: Props) => {
  const listItemStyles = useStyles();
  const src = link.icon ?? getFallbackIcon(link.title);
  return (
    <ListItem alignItems="flex-start" className={listItemStyles.root}>
      <ListItemAvatar>
        <Avatar className={listItemStyles.large} alt={link.title} src={src} variant="square" />
      </ListItemAvatar>
      <ListItemText
        classes={{ root: listItemStyles.listItemRoot, primary: listItemStyles.secondary }}
        primary={
          <>
            <Typography variant="h4" color="textPrimary">
              {link.title}
            </Typography>
            <Typography component="span" variant="body2" color="textPrimary">
              {link.description}
            </Typography>
            <Link href={link.href} target="_blank" rel="noopener">
              {'>'} {link.title}
            </Link>
          </>
        }
      />
    </ListItem>
  );
};
