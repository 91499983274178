import { Chip, Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';

type Props = {
  tags: string[];
  selectedTags: string[];
  onTagClick: (tag: string) => void;
};
const useStyles = makeStyles(theme => ({
  list: {
    paddingTop: theme.spacing(2),
  },
}));
export const LinksTags = ({ tags, onTagClick, selectedTags }: Props) => {
  const styles = useStyles();
  return (
    <Grid container spacing={1} className={styles.list}>
      {tags.map((tag, index) => (
        <Grid item key={index}>
          <Chip
            variant="outlined"
            // @ts-ignore
            color={selectedTags.includes(tag) ? 'secondary' : 'info'}
            onClick={() => onTagClick(tag)}
            label={tag}
          />
        </Grid>
      ))}
    </Grid>
  );
};
