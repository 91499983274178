import React from 'react';

import { EntityLayout } from '@backstage/plugin-catalog';
import { techdocsContent } from '../techdocs/Content';
import { Grid } from '@material-ui/core';
import { EntityProvidedApisCard } from '@backstage/plugin-api-docs';
import { overviewContent } from '../catalog/CommonRoutes';
import { EntitySnykContent, isSnykAvailable } from 'backstage-plugin-snyk';

export const ServicePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/snyk" title="Snyk" if={isSnykAvailable}>
      <EntitySnykContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <EntityProvidedApisCard />
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
