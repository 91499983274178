import { Content, Page } from '@backstage/core-components';
import { CatalogTable } from '@backstage/plugin-catalog';
import { CatalogFilterLayout, EntityListProvider, EntityKindPicker } from '@backstage/plugin-catalog-react';
import React, { FunctionComponent } from 'react';

export const TeamsOverview: FunctionComponent<{}> = () => {
  return (
    <Page themeId="home">
      <EntityListProvider>
        <Content>
          <CatalogFilterLayout>
            <EntityKindPicker allowedKinds={['group']} initialFilter="group" hidden />
            <CatalogFilterLayout.Content>
              <CatalogTable actions={[]} />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </Content>
      </EntityListProvider>
    </Page>
  );
};
