import { Content, Page } from '@backstage/core-components';
import { CatalogTable } from '@backstage/plugin-catalog';
import { CatalogFilterLayout, EntityListProvider, EntityTypePicker } from '@backstage/plugin-catalog-react';
import React, { FunctionComponent } from 'react';

export const ServicesOverview: FunctionComponent<{}> = () => {
  return (
    <Page themeId="home">
      <EntityListProvider>
        <Content>
          <CatalogFilterLayout>
            <EntityTypePicker initialFilter="service" hidden />
            <CatalogFilterLayout.Content>
              <CatalogTable actions={[]} />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </Content>
      </EntityListProvider>
    </Page>
  );
};
